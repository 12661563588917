// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-business-contact-jsx": () => import("./../../../src/pages/business/contact.jsx" /* webpackChunkName: "component---src-pages-business-contact-jsx" */),
  "component---src-pages-business-index-jsx": () => import("./../../../src/pages/business/index.jsx" /* webpackChunkName: "component---src-pages-business-index-jsx" */),
  "component---src-pages-business-language-solutions-jsx": () => import("./../../../src/pages/business/language-solutions.jsx" /* webpackChunkName: "component---src-pages-business-language-solutions-jsx" */),
  "component---src-pages-education-contact-jsx": () => import("./../../../src/pages/education/contact.jsx" /* webpackChunkName: "component---src-pages-education-contact-jsx" */),
  "component---src-pages-education-index-jsx": () => import("./../../../src/pages/education/index.jsx" /* webpackChunkName: "component---src-pages-education-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-training-centers-contact-jsx": () => import("./../../../src/pages/training-centers/contact.jsx" /* webpackChunkName: "component---src-pages-training-centers-contact-jsx" */),
  "component---src-pages-training-centers-index-jsx": () => import("./../../../src/pages/training-centers/index.jsx" /* webpackChunkName: "component---src-pages-training-centers-index-jsx" */),
  "component---src-pages-training-centers-language-solutions-jsx": () => import("./../../../src/pages/training-centers/language-solutions.jsx" /* webpackChunkName: "component---src-pages-training-centers-language-solutions-jsx" */),
  "component---src-templates-content-index-jsx": () => import("./../../../src/templates/content/index.jsx" /* webpackChunkName: "component---src-templates-content-index-jsx" */),
  "component---src-templates-resources-index-jsx": () => import("./../../../src/templates/resources/index.jsx" /* webpackChunkName: "component---src-templates-resources-index-jsx" */)
}

